import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PageWrapper from '../components/PageWrapper'
import { Section, Title, Text, Box } from '../components/Core'
import { useSelector } from 'react-redux'

const NavStyled = styled(Nav)`
    border-radius: 10px;
    border: 1px solid #eae9f2;
    background-color: ${props =>
      props.userdarkmode === 'true' ? 'dark' : 'white'};
    padding-top: 15px;
    padding-bottom: 15px;
    a {
      color: #3377C6 !important;
      font-size: 18px !important;
      padding: 8px 21px;
      &:hover {
      },
      &:active,
      &:visited,
      &.nav-link {
        &.active {
          color: ${props =>
            props.userdarkmode === 'true' ? 'white' : 'black'}  !important;
        }
      },
    }
  `

const Faq = exchCode => {
  const { t, i18n, ready } = useTranslation( ['exchanges', 'tables'] , { useSuspense: false });

  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
   
  return ready && (
    <>
      <PageWrapper headerDark footerDark>
        <Section bg={userDarkMode ? 'black' : 'bg'} className="pb-0">
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="FAQ1">
              <Row>
                {
                  typeof t(`${exchCode.exchCode}Faqs.itIs`) !== 'undefined' && 
                  t(`${exchCode.exchCode}Faqs.itIs`) !== '' && 
                  !t(`${exchCode.exchCode}Faqs.itIs`).includes('Faqs') ?
                  <Col lg="12">
                    <Title
                      color={userDarkMode ? 'light' : 'dark'}
                      variant="hero-smaller"
                    >
                      FAQs
                    </Title>
                  </Col>
                  :
                  null
                }
                {
                  typeof t(`${exchCode.exchCode}Faqs.itIs`) !== 'undefined' && 
                  t(`${exchCode.exchCode}Faqs.itIs`) !== '' && 
                  !t(`${exchCode.exchCode}Faqs.itIs`).includes('Faqs') ?
                  <Col md="6" className="mb-5 mb-md-0">
                    <NavStyled
                      style={{ cursor: 'pointer' }}
                      userdarkmode={userDarkMode ? 'true' : 'false'}
                      className="flex-column mr-md-5"
                    >
                      {/* {faqKeysArray ? faqKeysArray.map((element, index) => {
                        console.log('INMAP', element);
                        return (
                          <Nav.Link key={index} eventKey={index}>{element}</Nav.Link>
                        );
                      }) : null} */}
                      {
                        t(`${exchCode.exchCode}Faqs.whatIs`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.whatIs`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.itIs`) === ''
                        ?
                        null
                        :
                        <Nav.Link eventKey="FAQ1">
                          {t(`${exchCode.exchCode}Faqs.whatIs`)}
                        </Nav.Link>
                      }
                      {
                        t(`${exchCode.exchCode}Faqs.isItSafe`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.isItSafe`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.companySecurity`) === ''
                        ?
                        null
                        :
                        <Nav.Link eventKey="FAQ2">
                          {t(`${exchCode.exchCode}Faqs.isItSafe`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.wasItHacked`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.wasItHacked`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.hacked`) === ''
                        ?
                        null
                        :
                        <Nav.Link eventKey="FAQ3">
                          {t(`${exchCode.exchCode}Faqs.wasItHacked`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.whereIsIt`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.whereIsIt`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.itIsLocated`) === ''
                        ?
                        null
                        :
                        <Nav.Link eventKey="FAQ4">
                          {t(`${exchCode.exchCode}Faqs.whereIsIt`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.howDoesItWork`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.howDoesItWork`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.itWorks`) === ''
                        ?
                        null
                        :
                        <Nav.Link eventKey="FAQ5">
                          {t(`${exchCode.exchCode}Faqs.howDoesItWork`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.whoOwnsIt`).includes(
                          'undefined'
                        ) || 
                        t(`${exchCode.exchCode}Faqs.whoOwnsIt`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.itIsOwned`) === ''
                        ?
                        null
                        : 
                        <Nav.Link eventKey="FAQ6">
                          {t(`${exchCode.exchCode}Faqs.whoOwnsIt`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.creditCards`) === '' ||
                        t(`${exchCode.exchCode}Faqs.doesItAcceptCreditCards`).includes(
                          'Faqs'
                        ) ||
                        t(
                          `${exchCode.exchCode}Faqs.doesItAcceptCreditCards`
                        ).includes('undefined') 
                        ?
                        null
                        : 
                        <Nav.Link eventKey="FAQ7">
                          {t(`${exchCode.exchCode}Faqs.doesItAcceptCreditCards`)}
                        </Nav.Link>
                      }
                      
                      {
                        t(`${exchCode.exchCode}Faqs.supportedCountries`) === '' ||
                        t(`${exchCode.exchCode}Faqs.whichCountries`).includes(
                          'Faqs'
                        ) ||
                        t(`${exchCode.exchCode}Faqs.whichCountries`).includes(
                          'undefined'
                        ) ?
                        null
                        :
                        <Nav.Link eventKey="FAQ8">
                          {t(`${exchCode.exchCode}Faqs.whichCountries`)}
                        </Nav.Link> 
                      }
                      
                    </NavStyled>
                  </Col>
                  :
                  null
                }
                <Col md="6">
                  <Tab.Content>
                    {/* {faqsKeys.faqsValues ? faqsKeys.faqsValues.map((element, index) => {
                      if (typeof element === 'string') {
                        // console.log('INMAP', element);
                        return (
                          <Tab.Pane key={index} eventKey={index}>
                            <Box>
                              <Box mb={4}>
                                <Title variant="card" mb={3} fontSize="24px">
                                  {faqsKeys.faqsKeys[index]}
                                </Title>
                                <Text variant="small">
                                  {element}
                                </Text>
                              </Box>
                            </Box>
                          </Tab.Pane>
                        );
                      }  else return null; 
                    }) : null} */}
                    <Tab.Pane eventKey="FAQ1">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(`${exchCode.exchCode}Faqs.whatIs`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.whatIs`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.whatIs`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.itIs`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.itIs`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.itIs`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ2">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(`${exchCode.exchCode}Faqs.isItSafe`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.isItSafe`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.isItSafe`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(
                              `${exchCode.exchCode}Faqs.companySecurity`
                            ).includes('undefined')
                            || t(`${exchCode.exchCode}Faqs.companySecurity`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.companySecurity`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ3">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(`${exchCode.exchCode}Faqs.wasItHacked`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.wasItHacked`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.wasItHacked`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.hacked`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.hacked`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.hacked`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ4">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(`${exchCode.exchCode}Faqs.whereIsIt`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.whereIsIt`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.whereIsIt`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.itIsLocated`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.itIsLocated`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.itIsLocated`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ5">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(
                              `${exchCode.exchCode}Faqs.howDoesItWork`
                            ).includes('undefined')
                            || t(`${exchCode.exchCode}Faqs.howDoesItWork`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.howDoesItWork`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.itWorks`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.itWorks`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.itWorks`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ6">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(`${exchCode.exchCode}Faqs.whoOwnsIt`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.whoOwnsIt`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.whoOwnsIt`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.itIsOwned`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.itIsOwned`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.itIsOwned`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ7">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(
                              `${exchCode.exchCode}Faqs.doesItAcceptCreditCards`
                            ).includes('undefined')
                            || t(`${exchCode.exchCode}Faqs.doesItAcceptCreditCards`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(
                                  `${exchCode.exchCode}Faqs.doesItAcceptCreditCards`
                                )}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(`${exchCode.exchCode}Faqs.creditCards`).includes(
                              'undefined'
                            ) || t(`${exchCode.exchCode}Faqs.creditCards`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.creditCards`)}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="FAQ8">
                      <Box>
                        <Box mb={4}>
                          <Title
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="card"
                            mb={3}
                            fontSize="24px"
                          >
                            {t(
                              `${exchCode.exchCode}Faqs.whichCountries`
                            ).includes('undefined')
                            || t(`${exchCode.exchCode}Faqs.whichCountries`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(`${exchCode.exchCode}Faqs.whichCountries`)}
                          </Title>
                          <Text
                            color={userDarkMode ? '#FAFAFA' : 'black'}
                            variant="small"
                          >
                            {t(
                              `${exchCode.exchCode}Faqs.supportedCountries`
                            ).includes('undefined')
                            || t(`${exchCode.exchCode}Faqs.supportedCountries`).includes(
                              'Faqs'
                            )
                              ? ''
                              : t(
                                  `${exchCode.exchCode}Faqs.supportedCountries`
                                )}
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Faq
